import React from 'react';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  IMPRINT_PATH, DATA_POLICY_PATH, COOKIES_POLICY_PATH, FAQ_PATH,
} from '../paths';
import {
  blackRock, orange, boulder, white,
} from '../../colors/index';
import neusoftLogo from '../../assets/images/white-neusoft-logo.svg';
import UpButton from '../button/UpButton';

const year = new Date().getFullYear();

const useStyle = makeStyles(() => ({
  wrapper: {
    height: 'auto',
    width: '100%',
    color: 'white',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${blackRock}`,
    '@media (orientation: portrait)': {
      flexDirection: 'column',
    },
  },
  neusoftDetails: {
    height: 'auto',
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'white',
    '@media (orientation: portrait)': {
      width: '80%',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
    '@media(max-device-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
  },
  companyPagesAndSocialLinks: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    '@media (max-width:600px)': {
      paddingTop: '1.5rem',
      width: '35%',
      alignItems: 'flex-start',
    },
    '@media(orientation: portrait) and (min-width: 600px)': {
      paddingTop: '2.5rem',
      width: '35%',
      alignItems: 'flex-start',
    },
  },
  pages: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      margin: '0.5rem 0',
      '@media (max-width:600px)': {
        margin: '0.2rem 0',
      },
      '@media(orientation: portrait) and (min-width: 600px)': {
        margin: '0.4rem 0',
      },
    },
    '& span': {
      opacity: '0.5',
      margin: '0.3rem 0',
      color: 'white',
      fontFamily: 'neo-sans !important',
      letterSpacing: 0,
      fontWeight: '300',
    },
  },
  companyPages: {
    color: 'white',
    paddingTop: '0.7rem',
    paddingBottom: '0.5rem',
    '@media(orientation: portrait)': {
      display: 'flex',
      flexDirection: 'row',
      width: 'auto',
      justifyContent: 'space-between',
    },
  },
  contact: {
    display: 'flex',
    height: 'auto',
    textAlign: 'right',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'space-between',
    color: orange,
    fontFamily: 'neo-sans !important',
    width: '50%',
    '@media (orientation: landscape) and (max-width:1200px)': {
      justifyContent: 'space-evenly',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      width: '70%',
    },
    '& h6': {
      marginTop: '4.875rem',
      marginBottom: '2.5rem',
      '@media (max-width:600px)': {
        marginBottom: '1.5rem',
        marginTop: '0',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        marginBottom: '1rem',
        marginTop: '0',
      },
      '@media (orientation: landscape) and (max-device-width:900px)': {
        marginTop: '1rem',
      },
    },
    '& h6 span': {
      display: 'block',
    },
    '& h5 span': {
      display: 'block',
    },
    '@media (orientation: portrait)': {
      width: '65%',
      padding: '0',
      marginRight: '0',
    },
    '@media (max-width:600px)': {
      paddingTop: '1.5rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      paddingTop: '5rem',
    },
  },
  title: {
    color: orange,
    fontWeight: 'bold !important',
  },
  contactDetails: {
    width: '80%',
    alignSelf: 'flex-end',
    marginBottom: '1.875rem',
    '& p': {
      margin: '0.5% 0',
      color: orange,
    },
    '& a': {
      color: orange,
      textDecoration: 'none',
    },
    '@media (max-width:600px)': {
      width: '90%',
      marginBottom: '1.2rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      width: '80%',
    },
  },
  companyContent: {
    '& h6,p': {
      color: white,
      opacity: '0.8',
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        marginBottom: '0',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        marginBottom: '0.5rem',
      },
    },
  },
  weAreSocial: {
    width: '13rem',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: '3.313rem',
    '@media (orientation:landscape) and (max-width:1300px)': {
      width: '10rem',
    },
    '& a svg': {
      height: '2.3rem',
      width: 'auto',
      '@media (max-width:600px)': {
        height: '1.7rem',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        height: '2.6rem',
      },
    },
    '@media (max-width:600px)': {
      display: 'flex',
      width: '50%',
      marginTop: '0',
      marginBottom: '2.5rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginTop: '1rem',
      marginBottom: '3.2rem',
      width: '14rem',
    },
  },
  copyRight: {
    color: `${boulder}`,
    paddingBottom: '1.125rem',
    '& span': {
      color: white,
      opacity: '0.4',
      textTransform: 'normal',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: '300',
    },
    '@media (orientation: portrait)': {
      textAlign: 'center',
      position: 'absolute',
      left: '0',
      width: '100%',
      bottom: '0',
      paddingBottom: '1.125rem',
      '@media (max-width:600px)': {
        '& span': {
          fontSize: '0.625rem',
          lineHeight: '0.875rem',
        },
      },
    },
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  whiteLink: {
    color: white,
    marginTop: '0.5rem',
  },
  logo: {
    height: '1.25rem',
    width: 'auto',
    marginBottom: '2.875rem',
    '@media (max-width:600px)': {
      marginBottom: '0.5rem',
      height: '1rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginBottom: '1.688rem',
    },
  },
}));

const Footer = () => {
  const classes = useStyle();
  const tabletView = useMediaQuery('@media (min-width:600px) and (orientation: portrait)');
  const mobileView = useMediaQuery('@media (orientation: portrait) and (max-width:600px)');

  return (
    <div className={classes.wrapper}>
      <div className={classes.neusoftDetails}>
        <div className={classes.companyPagesAndSocialLinks}>
          <div className={classes.companyContent}>
            <div>
              <img className={classes.logo} src={neusoftLogo} alt="Neusoft" />
            </div>
            <Typography variant={tabletView || mobileView ? 'subtitle2' : 'body1'}>
              Company
            </Typography>
            <div className={classes.companyPages}>
              <div className={classes.pages}>
                <NavLink
                  to={IMPRINT_PATH}
                  className={classes.navLink}
                >
                  <Typography component="span">
                    Imprint
                  </Typography>
                </NavLink>
                <NavLink
                  to={DATA_POLICY_PATH}
                  className={classes.navLink}
                >
                  <Typography component="span">
                    Data Policy
                  </Typography>
                </NavLink>
                <NavLink
                  to={COOKIES_POLICY_PATH}
                  className={classes.navLink}
                >
                  <Typography component="span">
                    Cookies Policy
                  </Typography>
                </NavLink>
                <NavLink
                  to={FAQ_PATH}
                  className={classes.navLink}
                >
                  <Typography component="span">
                    FAQ
                  </Typography>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.contact}>
          <Typography variant="subtitle2" className={classes.title}>
            Ready for takeoff?
            <span>Let’s talk business!</span>
          </Typography>
          <div className={classes.contactDetails}>
            <Typography variant="body1">
              <b>Phone: </b>
              <a href="tel:+40 364 410 111">+40 364 410 111</a>
            </Typography>
            <Typography variant="body1">
              <b>Email: </b>
              {' '}
              <a href="mailto:office@neusoft.ro">
                office@neusoft.ro
              </a>
            </Typography>
            <Typography variant="body1">
              <b>Address: </b>
              44 Clinicilor Street,
              <br />
              <span> Cluj-Napoca, Romania </span>
            </Typography>
          </div>
          <div className={classes.weAreSocial}>
            <a className={classes.link} href="https://www.instagram.com/neusoft_edc/" rel="noreferrer" target="_blank">
              <InstagramIcon style={{ fill: white }} />
            </a>
            <a className={classes.link} href="https://www.facebook.com/edc.cluj" rel="noreferrer" target="_blank">
              <FacebookIcon style={{ fill: white }} />
            </a>
            <a className={classes.link} href="https://www.linkedin.com/company/neusoft-edc/" rel="noreferrer" target="_blank">
              <LinkedInIcon style={{ fill: white }} />
            </a>
          </div>
          <div className={classes.copyRight}>
            <Typography component="span">
              Neusoft EDC ©
              {year}
            </Typography>
          </div>
        </div>
      </div>
      <UpButton />
    </div>
  );
};

export default Footer;
