import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import React from 'react';
import { COOKIES_POLICY_PATH } from '../paths';

export const contentInEnglish = (action, path, closeAction) => (
  <>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>Definitions and key terms</span>
      </Typography>
      {!action && <hr />}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        To help explain things as clearly as possible in our Cookies Policy,
        every time any of these terms are referenced, they are strictly defined as
        {' '}
        <br />
        COOKIE:
        {' '}
        small amount of data generated by a website and saved by your web browser.
        It is used to identify your browser, provide analytics, remember information
        about you such as your language preference or login information.
        <br />
        DEVICE:
        {' '}
        any internet connected device such as a phone, tablet, computer or any other
        device that can be used to visit
        {' '}
        <a href="https://neusoft.ro/" target="_blank" rel="noreferrer">
          www.neusoft.ro
        </a>
        {' '}
        and use its services.
        <br />
        WEBSITE:
        {' '}
        Neusoft EDC’s
        site, which can be accessed via this URL:
        {' '}
        <a href="https://neusoft.ro/" target="_blank" rel="noreferrer">
          www.neusoft.ro
        </a>
        .
        <br />
        YOU: a person or entity using Neusoft EDC’s services.
      </Typography>
    </div>

    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          Introduction
        </span>
      </Typography>
      {!action && <hr />}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        This Cookies
        Policy explains how SC Neusoft EDC SRL
        (“our company”, “Neusoft EDC”, “we”, “us”, and “ours”), use
        cookies and similar technologies to recognize you when
        you visit our platform, including without limitation any
        related URLs, mobile or localized versions and
        related domains / sub-domains (“websites”).
        It explains what these technologies are and why we use them,
        as well as the choices you have to control them.
      </Typography>
    </div>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          What is a cookie?
        </span>
      </Typography>
      {!action && <hr />}
      {' '}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        A cookie is a small text file that is stored on your computer or on
        any other Internet connected devices in order to identify your browser,
        provide analytics, remember information about you such as your language
        preferences or login information. They’re completely safe and do not
        contain any harmful malware or virus.
        <br />
        <br />
        Cookies are classified into three different categories:
        1. Functional 2. Statistical 3. Marketing
      </Typography>
    </div>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          What type of cookies does Neusoft EDC use?
        </span>
      </Typography>
      {!action && <hr />}
      {' '}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        Our website only uses Functional cookies, that are absolutely
        necessary to make our platform work and allow the best navigation experience.
        These cookies are strictly necessary to enable core functionalities
        such as security, network management, your cookies preferences and
        accessibility. Without them you wouldn’t be able to use our basic
        services. You may disable these by changing your browser settings,
        but this may affect how our website functions.
      </Typography>
    </div>

    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          How can you manage cookies?
        </span>
      </Typography>
      {!action && <hr />}

      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        Most browsers allow you to control cookies through their “settings” preferences.
        However, if you limit the ability of websites to set cookies, you will make your
        overall user experience not optimal, since it will no longer be personalized for you.
        It may also stop you from saving customized settings like login information.
        Browser manufacturers provide help pages related to cookies management in their products.
        <br />
        <br />
        Different browsers make different controls available to you. Disabling a cookie or category
        of cookies does not delete the cookies from your browser, you will need to do this yourself
        from within your browser, you should visit your browser’s help menu for more information.
        Please see below for more information (click the appropriate link):
        <br />
      </Typography>
      <ol>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noreferrer">
              Google Chrome
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noreferrer">
              Microsoft Edge
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US" target="_blank" rel="noreferrer">
              Mozilla Firefox
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">
              Opera
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noreferrer">
              Safari (mobile)
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://support.apple.com/ro-ro/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">
              Safari (desktop)
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            component={action ? 'p' : 'h6'}
          >
            <a href="https://discover.hubpages.com/technology/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device" target="_blank" rel="noreferrer">
              Android
            </a>
          </Typography>
        </li>
      </ol>
    </div>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          Changes to our cookies policy
        </span>
      </Typography>
      {!action && <hr />}
      {' '}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        We may change our service and policies, and we may need to make changes
        to this Cookies Policy so that they accurately reflect our service and policies.
        Unless otherwise required by law, we will notify you (for example, through our service)
        before we make changes to this Cookies Policy and give you an opportunity to
        review them before they go into effect. Then, if you continue to use our service,
        you will be bound by the updated Cookies Policy. If you don’t want to agree
        to this or if you don’t want any updated Cookies Policy, you can stop using our platform.
        <br />
        <br />
        This Cookies Policy was last updated on 20 August 2022.
      </Typography>
    </div>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          Your consent
        </span>
      </Typography>
      {!action && <hr />}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        You can change your consent by either deleting cookies from your browser or by
        {action ? (
          <>
            {' '}
            clicking the link below:
            <br />
            <button type="button" onClick={action}>
              <Typography variant="body1">Change your consent</Typography>
            </button>
          </>
        ) : (
          <>
            {' '}
            altering your initial choice at a later date by accessing
            {' '}
            <Link onClick={closeAction} to={path}>Cookies Policy</Link>
          </>
        )}
      </Typography>
    </div>
    <div>
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        <span>
          Contact us
        </span>
      </Typography>
      {!action && <hr />}
      {' '}
      <Typography
        variant="body1"
        component={action ? 'p' : 'h6'}
      >
        Don’t
        hesitate to contact us if you have any questions regarding
        our Cookies Policy.
        <br />
        Email:
        {' '}
        <a href="mailto:dpo@neusoft.ro">
          dpo@neusoft.ro
        </a>
        <br />
        Phone Number:
        <a href="tel:+40 364 410 111"> +40 364 410 111</a>
        <br />
        Address: Clinicilor 44, Cluj-Napoca 400006
      </Typography>
    </div>
  </>
);

export const contentInRomanian = (closeAction) => (
  <Typography
    variant="body1"
    component="h6"
  >
    <p>DEFINIȚII ȘI TERMENI CHEIE</p>
    <hr />
    Pentru a explica lucrurile cât mai clar posibil în această politică de
    utilizare a cookie-urilor, de fiecare dată când se face referire la
    oricare dintre acești termeni, ei sunt strict definiți ca:
    {' '}
    <br />
    COOKIE:
    {' '}
    cantitate mică de date generată de un site web și salvată de browserul dvs.
    web. Datele sunt folosite pentru a vă identifica browserul, pentru a ne
    furniza analize de date, pentru a vă aminti informații despre navigarea
    dvs., cum ar fi preferințele pentru limba folosită sau informații de conectare.
    <br />
    DISPOZITIV:
    {' '}
    orice dispozitiv conectat la internet, cum ar fi un telefon, o tabletă,
    un computer sau orice alt dispozitiv care poate fi utilizat pentru a
    vizita
    {' '}
    <a href="https://neusoft.ro/" target="_blank" rel="noreferrer">
      www.neusoft.ro
    </a>
    {' '}
    și pentru a utiliza serviciile acestui site.
    <br />
    SITE WEB:
    {' '}
    site-ul Neusoft EDC, care poate fi accesat prin acest URL:
    {' '}
    <a href="https://neusoft.ro/" target="_blank" rel="noreferrer">
      www.neusoft.ro
    </a>
    .
    <br />
    VOI:
    {' '}
    o persoană sau entitate care utiliează serviciile oferite de Neusoft EDC.
    <p>INTRODUCERE </p>
    <hr />
    Această politică privind cookie-urile explică modul în care SC Neusoft EDC SRL
    („Neusoft”, „noi” și „ale noastre”) folosește cookie-uri și tehnologii
    similare pentru a vă recunoaște atunci când vizitați platforma noastră,
    inclusiv fără limitare și orice alte adrese URL conexe, mobile sau versiuni
    localizate și domenii / subdomenii conexe („site-uri web”). Această
    politică explică ce sunt aceste tehnologii și de ce le folosim,
    precum și opțiunile disponbile pentru a le controla.
    <p>CE ESTE UN COOKIE?</p>
    <hr />
    {' '}
    Un cookie este un mic fișier text care este stocat pe computerul dvs. sau pe
    alt dispozitiv conectat la internet folosit pentru a vă identifica browserul,
    pentru a furniza analize de date, pentru a vă aminti informații despre dvs.,
    cum ar fi preferința dvs. de limbă sau informațiile de conectare. Sunt complet
    sigure și nu conțin malware sau viruși care să afecteze integritatea
    dispozitivului dumneavoastră.
    <br />
    <br />
    Cookie-urile sunt clasificate în trei categorii,
    în funcție de rolul lor: 1. Funcțional 2. Statistice 3. Marketing
    <p>
      CE TIP DE COOKIE-URI UTILIZEAZĂ NEUSOFT EDC?
    </p>
    <hr />

    Site-ul nostru web folosește doar cookie-uri de tip funcțional, care sunt strict
    necesare pentru ca platforma noastră să funcționeze corect și să permită cea mai
    bună experiență de navigare. Acestea sunt esențiale pentru a vă permite să navigați
    pe platforma noastră și să utilizați caracteristicile acesteia. Dacă eliminați
    sau dezactivați aceste cookie-uri, nu vă putem garanta că veți putea utiliza
    platforma noastră. Le puteți dezactiva din setările browserului dumneavoastră,
    însă asta ar putea să afecteze modul de funcționare a site-ului nostru web.

    <p>CUM PUTEȚI GESTIONA COOKIE-URILE?</p>
    <hr />
    {' '}
    Majoritatea browserelor vă permit să controlați cookie-urile prin preferințele
    lor de „setări”. Cu toate acestea, dacă limitați capacitatea site-urilor web de
    a seta cookie-uri, este posibil să vă înrăutățiți experiența generală de utilizare,
    deoarece nu va mai fi personalizată pentru dvs. De asemenea, limitarea vă poate
    împiedica să salvați setări personalizate, cum ar fi informații de conectare.
    Producătorii de browsere oferă pagini de ajutor referitoare la gestionarea
    cookie-urilor în produsele lor. Vă rugăm să consultați paginile de mai jos
    pentru mai multe informații:
    <ol>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noreferrer">
            Google Chrome
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noreferrer">
            Microsoft Edge
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US" target="_blank" rel="noreferrer">
            Mozilla Firefox
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">
            Opera
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noreferrer">
            Safari (mobile)
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://support.apple.com/ro-ro/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">
            Safari (desktop)
          </a>
        </Typography>
      </li>
      <li>
        <Typography
          variant="body1"
          component="h6"
        >
          <a href="https://discover.hubpages.com/technology/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device" target="_blank" rel="noreferrer">
            Android
          </a>
        </Typography>
      </li>
    </ol>
    <p>MODIFICĂRI ALE POLITICII NOASTRE PRIVIND UTILIZAREA COOKIE-URILOR</p>
    <hr />
    {' '}
    Este posibil să ne schimbăm serviciul și politicile și este posibil să trebuiască
    să facem modificări la politica prezentă privind utilizarea cookie-urilor,
    astfel încât aceasta să reflecte cu exactitate serviciul și politicile actuale.
    Cu excepția cazului în care legea impune altfel, vă vom notifica (de exemplu,
    prin intermediul serviciului nostru) înainte de a face modificări la această
    politică privind utilizarea cookie-urilor și vă vom oferi posibilitatea de
    a o examina înainte ca aceasta să intre în vigoare. Apoi, dacă continuați
    să utilizați serviciul nostru, o veți face sub politica actualizată privind
    utilizarea cookie-urilor. Dacă nu sunteți de acord cu aceasta sau cu orice
    politică actualizată privind cookie-urile vă rugăm să nu continuați navigarea
    pe site-ul nostru web.
    <br />
    <br />
    Prezenta Politică de Utilizare Cookie-uri a fost modificată la 20 august 2022.
    <p>CONSIMȚĂMÂNTUL TĂU</p>
    <hr />
    {' '}
    Puteți să vă retrageți consimțământul prin ștergerea fișierelor de tip cookie
    din browser sau prin modificarea ulterioară a acordului dumneavoastră prin accesarea
    {' '}
    <Link onClick={closeAction} to={COOKIES_POLICY_PATH}>Cookies Policy</Link>
    <br />
    <br />
    <p>CONTACTEAZĂ-NE</p>
    <hr />
    {' '}
    Nu ezitați să ne contactați dacă aveți întrebări cu
    privire la politica noastră de utilizare a cookie-urilor.
    <br />
    Prin e-mail:
    {' '}
    <a href="mailto:dpo@neusoft.ro">
      dpo@neusoft.ro
    </a>
    <br />
    {' '}
    Prin telefon:
    <a href="tel:+40 364 410 111"> +40 364 410 111</a>
    <br />
    La adresa: Strada Clinicilor 44, Cluj-Napoca 400006
  </Typography>
);
