import { Typography } from '@material-ui/core';
import React from 'react';

export const gdprInRomanian = (
  <Typography
    variant="h6"
  >
    <p>Despre noi</p>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    Neusoft EDC este un furnizor de soluții IT&C cu sediul în Cluj-Napoca,
    România - companie înființată în 2007, având ca activitate realizarea
    și testarea de software la comandă (custom), servicii de consultanță
    în tehnologia informației și procese de business outsourcing
    - specializarea firmei fiind oferirea de soluții software complete,
    cuprinzând aplicații mobile, platforme web, server back-end,
    baze de date, software integrat în echipamente electronice,
    integrare de senzori ș.a.m.d. Serviciile de dezvoltare a software-ului
    sunt prestate la cererea clienților (on-demand), adesea subcontractate.
    Lucrăm cu clienți din Europa, SUA și Asia, din industrii precum smart fitness
    și producători de echipamente de sport, antrenamente și sănătate,
    dispozitive conectate, IoT, telecomunicații, servicii financiare,
    producători de echipamente electronice, furnizori de logistică,
    educație, servicii online, automotive. Firma din România este IMM,
    înregistrată în Europa ca filială independentă sub umbrela corporației Neusoft.
    {' '}
    <p>Prezentare generală</p>
    {' '}
    Regulamentul UE nr. 679/2016 privind protecția datelor, numit și „GDPR”, a
    devenit aplicabil începând cu 25 mai 2018, în contextul prelucrării și
    liberei circulații a datelor cu caracter personal, iar noi ne aliniem
    acestor standarde europene în vigoare, cu responsabilitate asupra modului
    în care avem grijă de datele dumneavoastră personale. Această informare
    explică modul în care organizația noastră vă prelucrează datele personale
    colectate în urma utilizării website-ului nostru. SC Neusoft EDC SRL („Neusoft EDC”)
    – în calitate de operator de date cu caracter personal cu sediul în Cluj-Napoca,
    str. Clinicilor nr. 44, jud. Cluj, România, transmite prezenta INFORMARE PRIVIND
    PRELUCRAREA DATELOR CU CARACTER PERSONAL ÎN SCOP DE OFERTARE DE BUSINESS SAU
    RECRUTARE PERSONAL, dorind să vă informeze cu privire la procesarea datelor
    personale pe care le-a obținut în vederea unei posibile relații de colaborare
    cu organizația noastră. Acesta este un CONSIMȚĂMÂNT VALABIL ÎNTRE NEUSOFT EDC
    ȘI DVS., prin care vă informăm că dorim să colectăm și să stocăm datele dvs.
    în baza noastră internă de date și despre drepturile dumneavoastră în legătură
    cu datele menționate, conform prevederilor stipulate în Regulamentul general privind
    protecția datelor nr. 679/2016.
    <p>Ce prelucrăm </p>
    {' '}
    Neusoft EDC prelucrează doar date personale cu caracter general necesare pentru
    ca relația de colaborare sau angajare să poată fi posibilă. Datele personale pe
    care le prelucrăm sunt: - pentru contacte de afaceri: nume, prenume, email, mesaj
    trimis, rol ca potențial client; - iar pentru recrutare: CV, nume și prenume,
    data și locul nașterii, domiciliu, număr de telefon, adresa de e-mail, informații
    referitoare la nivelul de educație (studii), experiența de lucru, seminarii
    sau alte cursuri, limbi străine cunoscute, competențe informatice/ tehnice,
    abilități personale, precum și alte aspecte comunicate de către dumneavoastră prin CV-ul depus.
    <p>Cum colectăm datele </p>
    {' '}
    Dumneavoastră ne trimiteți datele personale. Colectăm și procesăm date atunci când:
    ne trimiteți o cerere de informare prin formularul de contact de pe website din secțiunea
    ‘Where’; vă înregistrați online pentru una din pozițiile noastre deschise din secțiunea cariere
    ‘Join’; vă trimiteți CV-ul către echipa de recrutare ca răspuns la un anunț de angajare;
    luați legătura în mod voluntar sau ne trimiteți un feedback sau un mesaj prin unul din
    canalele de contact ale firmei noastre; navigați, vizualizați sau folosiți informații
    din website-ul nostru prin intermediul cookies din browser-ul pe care îl folosiți.
    Compania noastră mai poate primi datele dvs. indirect din una din următoarele surse
    posibile: website-uri și portaluri online unde dvs. v-ați completat personal și
    voluntar CV-ul, profilul profesional sau personal în scopul de a fi contactat pentru
    o ofertă profesională sau de angajare.
    <p> Care este scopul </p>
    {' '}
    Prelucrăm datele personale doar în scop legitim,
    și anume acela de a vă furniza oferte de colaborare,
    profesionale, de afaceri sau de lucru pentru poziții deschise/viitoare
    în cadrul societății noastre. Prelucrarea datelor personale este necesară
    și este întemeiată doar pentru a face aceste demersuri, la cererea dumneavoastră,
    sau înainte de încheierea unui contract de muncă, sau în scopul legitim al unor interese
    reciproce pentru a vă putea oferi noi oportunități de angajare sau de business.
    Vă asigurăm că nu vom utiliza datele pe care ni le-ați pus la dispoziție, decât
    și numai în condițiile prevăzute în Politica principală de protecție a datelor
    cu caracter personal în Neusoft EDC, precum și în legislația aplicabilă.
    <p> Cum stocăm datele </p>
    {' '}
    Compania noastră stochează datele dvs. în siguranță, la sediul nostru din Cluj-Napoca,
    România, în baza noastră de date internă, prevăzută cu precauțiile necesare pentru
    protecția datelor și la care are acces doar personal autorizat și limitat.
    <p> Pentru cât timp?</p>
    {' '}
    Vă asigurăm că datele furnizate de dvs. nu vor fi prelucrate în alte scopuri,
    decât cel declarat, nu vor fi comunicate de Neusoft EDC către alte entități
    și nu vor fi deținute pentru o perioadă care depășește 24 de luni calculat
    de la data prezentei notificări. Dacă firmei noastre i se solicită partajarea
    de date de către firme partenere sau ca parte din organizația corporației,
    aceasta va face acest lucru numai în condițiile prevăzute de instrumentele
    scrise puse la dispoziție de regulamentul GDPR.
    <p>Drepturile dvs.</p>

    {' '}
    Vă informăm că aveți următoarele drepturi cu privire la prelucrarea datelor dvs.
    cu caracter personal: dreptul de a obține din partea Neusoft EDC o confirmare
    că prelucrăm datele dvs. cu caracter personal și de a avea acces la acestea
    („dreptul de acces”);
    dreptul de a corecta sau completa datele personale inexacte sau incomplete
    („dreptul la rectificare”);
    dreptul la ștergerea datelor („dreptul de a fi uitat”);
    dreptul de a obține restricționarea prelucrării
    („dreptul la restricționarea prelucrării”); dreptul de a vă opune prelucrării datelor
    dumneavoastră cu caracter personal în scop publicitar („dreptul de opoziție”)
    (vă reamintim că nu folosim datele dvs. în scop publicitar); dreptul de a depune plângere
    în anumite circumstanțe față de modalitatea de prelucrare a datelor personale la
    Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal;
    dreptul de a se comunica datele dvs. prelucrate de Neusoft EDC sau de a solicita
    transmiterea acestora către un alt operator („dreptul la portabilitatea datelor”).
    <p>Contact și suport privind datele dvs.</p>

    {' '}
    Dacă ne trimiteți o solicitare, avem o lună de la primire pentru a vă răspunde.
    Vă puteți exercita oricând drepturile prevăzute de lege, sau puteți cere informații
    suplimentare. Puteți să luați legătura cu noi, prin următoarele modalități:

    <br />
    Trimițând o cerere în acest sens, datată si semnată, la sediul Neusoft EDC din Cluj-Napoca,
    str. Clinicilor nr. 44, jud. Cluj, cu mențiunea “în atenția responsabilului cu protecția
    datelor cu caracter personal”.
    <br />
    <div>
      Prin mesaj transmis la adresa de e-mail a Ofițerului responsabil cu protecția datelor DPO:
      {' '}
      <a href="mailto:dpo@neusoft.ro">
        dpo@neusoft.ro
      </a>
    </div>
    <div>
      Sunând la numărul de telefon:
      {' '}
      <a href="tel:+40 364 410 111">+40 364 410 111</a>
    </div>
    Compania noastră își revizuiește periodic politica de confidențialitate și protecție a datelor,
    iar orice modificare este actualizată în website. Această informare cu privire la protecția
    datelor personale conform GDPR a fost cel mai recent actualizată la data de 10 August 2022.
  </Typography>
);

export const gdprInEnglish = (
  <Typography
    variant="h6"
  >
    <p>About</p>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    Neusoft EDC is an IT&C services provider headquartered in Cluj-Napoca,
    Romania since 2007. Main activity is outsourced services, on-demand custom
    software development and testing, business consulting and business process outsourcing.
    We offer complete software solutions, with mobile applications, web platforms, back-end
    server, Cloud storage, embedded software, sensor integration, HMI, etc. We work with
    clients from Europe, USA and Asia, in the industries smart fitness and gym equipment,
    health and sport, wearables and connected devices, IoT, telecom, automotive, financial
    services & HR, logistics, online services. The Romanian company is a SME small/medium
    enterprise, registered in Europe as independent subsidiary of Neusoft Corporation.
    {' '}
    <p>Overview</p>
    {' '}
    The General Data Protection Regulation (“GDPR”) EU Regulation No. 679/2016 is applicable as of
    25 May 2018 to the processing and free movement of personal data, and we bring these European
    standards in line, with responsibility, for the way we take care of your personal data. This
    privacy notice will explain how our organization uses the personal data we collect from you
    when you use our website.
    SC Neusoft EDC SRL (“Neusoft EDC”) is a Personal Data Operator registered in Cluj-Napoca,
    44 Clinicilor street, Cluj County, Romania. As such, we publish this NOTICE ON THE
    PROCESSING OF PERSONAL
    DATA FOR STAFF RECRUITMENT OR BUSINESS CONTACTS, in order to inform you about the processing of
    the personal data that we received from you for a possible collaboration relationship with our
    organization. The following is a CONSENT FORM VALID BETWEEN NEUSOFT EDC AND YOURSELF, whereby
    we inform you that we would like to collect and store your data in our internal database, and
    about your rights in relation to said data, according to the provisions stipulated
    in the General
    Data Protection Regulation No. 679/2016.
    <p>What we process</p>
    {' '}
    Neusoft EDC processes only master personal identification information
    necessary for the business or employment relationship to exist (name,
    email address, phone number, etc.). Personal data that is processed
    – for business contacts: name, surname, email, message sent, role
    as potential client; – and for job applications: CV, name, date /
    place of birth, address, phone number, e-mail, information about
    the level of education (degrees), work experience, seminars and
    other courses, language skills, computer/technical skills, personal
    abilities and other aspects communicated by you through the resume submitted.
    <p> How we collect data </p>

    {' '}
    You directly provide our company with the data. We collect
    data and process data when you: send a business inquiry under the
    ‘Where’ contact  form; register online for an open position on our website
    under ‘Join’ section; send your CV to the recruitment team for a job ad;
    voluntarily contact us or provide feedback or a message on any of our
    contact details or channels; use or view our website via your browser’s
    cookies. Our company may also receive your data indirectly from the
    following sources: websites and portals where you personally and
    voluntarily filled out your resume, CV or professional or personal
    profile with the purpose of being contacted for a job offer.
    <p>What is the reason </p>

    {' '}
    We process personal data only for a legitimate purpose, namely to provide
    you with business offers on request, or offers for future open positions
    within our company. The processing of personal data is based on and is
    required to take action at your request before concluding a contract
    of employment; or for the legitimate interests we pursue in order to
    provide you with new business proposals or employment opportunities
    in the future. We assure you that we will use the data you provide
    to us solely for and only under the terms of the Neusoft EDC Personal
    Data Privacy Policy as well as applicable law.
    <p>How we store data </p>

    {' '}
    Our company securely stores your data in our internal database at our headquarters
    in Cluj-Napoca, Romania, where data security precautions are in place for data
    storage and only limited and permitted personnel has access to your personal data.
    <p>For how long?  </p>

    {' '}
    We ensure that data provided by you will not be processed for purposes other than stated,
    will not be communicated by Neusoft EDC to other entities and will not be held
    for a period exceeding 24 months from the date of this notice. If our company
    is in a position to share data with our partner companies or parent company
    as being part of a corporation, it will only do so under the written data
    protection tools provided by the GDPR regulation.
    <p>Your rights </p>

    {' '}
    As regards the processing of your personal data, we inform you that you have the
    following rights: the right to obtain from Neusoft EDC a confirmation that it processes
    your personal data and has access to it (“the right of access”);
    the right to correct or complete inaccurate or incomplete
    personal data (“the right of rectification”);
    the right to delete data (“the right to be forgotten”);
    the right to obtain restriction of processing (“the right to restrict processing”);
    the right to oppose the processing of your personal data for the purposes of advertising
    (please note that we do NOT collect your data for the purpose of advertising)
    (“the right to object to processing”); the right to submit a complaint for the processing of
    personal data with the National Supervisory
    Authority for Personal Data Processing, under certain conditions; the right to have your
    personal data processed by Neusoft EDC communicated to another data operator or request our
    company to transfer your data to another organization (“data portability”).
    <p>Contact and support for your data</p>

    {' '}
    By sending a written request, dated and signed, at Neusoft EDC office in Cluj-Napoca,
    str. Clinicilor, No. 44, Cluj county, Romania with the mention “to the attention of the person
    responsible for the protection of personal data”.
    <br />
    <div>
      By e-mail to our Data Protection Officer:
      {' '}
      <a href="mailto:dpo@neusoft.ro">
        dpo@neusoft.ro
      </a>
    </div>
    <div>
      By calling the phone number:
      {' '}
      <a href="tel:+40 364 410 111">+40 364 410 111</a>
    </div>
    Our company keeps its privacy policy under regular review and places
    any updates on this web page. This GDPR Data Privacy Notice was last updated on 10 August 2022.
  </Typography>
);
